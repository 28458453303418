export var LIVE_EVENT_STATUS = {
    UPCOMING: 'upcoming',
    PREVIOUS: 'previous',
    IN_PROGRESS: 'inProgress',
    CANCELLED: 'cancelled',
};
export var LIVE_STREAM_STATUS = {
    UPCOMING: 'upcoming',
    COMPLETED: 'completed',
    IN_PROGRESS: 'inProgress',
    CANCELLED: 'cancelled',
    ON_DEMAND: 'onDemand',
    ARCHIVED: 'archived',
};
export var APP_STORE_URL = {
    ANDROID: 'https://play.google.com/store/apps/details?id=com.theaccessplatform',
    APPLE: 'https://apps.apple.com/gb/app/the-ambassador-platform/id1451197802',
};
